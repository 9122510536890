import PlayerStore from "@/store/player";
import {YaPromocodeResult} from "@/store/player/types";
import ListenerManager from "@/managers/listener-manager";

export default class PromoUtils {

    public static handleYaPromocode(signature: string) {
        PlayerStore.saveYaPromocode(signature).then((res: YaPromocodeResult) => {
            for (let token of res.tokens) {
                // @ts-ignore
                window.ysdk.bonuses.consume(token);
            }
            ListenerManager.callNotifCallback();
        });
    }
}