import http from '../../axios';
import {SceneService} from "@/services/scene-service";
import {CharacterName, Lang} from "@/store/player/types";
import {Settings} from "@/constants/settings";
import {reload} from '@/utils/document-utils';
import CU from "@/utils/c-u";
import {Version} from "@/constants/version";
import {HSessionUtils} from "@/utils/h-session-utils";
import {IntegrationUtils} from "@/utils/integration-utils";

export default class SecurityStore {

    public static authenticateMystore(uid: string): Promise<void> {
        const params = new URLSearchParams();
        params.append('uid', uid);
        params.append('lang', Settings.LANG_TYPE.toString());
        params.append('currency', Settings.CURRENCY.toString());
        return http.post('/api/integration/mystore/client/authenticate', params);
    }

    public static registerTrial(characterAndCompanion: CharacterName): Promise<void> {

        const params = new URLSearchParams();
        params.append('characterAndCompanion', characterAndCompanion);
        params.append('domain', window.location.href);
        params.append('lang', Settings.LANG_TYPE.toString());
        params.append('sw', String(Math.round(CU.windowWidth())));
        params.append('sh', String(Math.round(CU.windowHeight())));
        params.append('cw', String(Math.round(CU.CANVAS_WIDTH)));
        params.append('ch', String(Math.round(CU.CANVAS_HEIGHT)));
        params.append('frontVersion', String(Version.VERSION_NUM));
        let hSession = HSessionUtils.getHSession();
        if (hSession) {
            params.append('h_session', hSession);
        }
        let ref = IntegrationUtils.getRefCode();
        if(ref){
            params.append('ref', ref);
        }
        return http.post('/api/security/registerTrial', params);
    }

    public static authenticateTelegram(): Promise<void> {
        const params = new URLSearchParams();
        params.append('lang', Settings.LANG_TYPE.toString());
        return http.post('/api/integration/telegram/authenticate', params);
    }

    public static authenticateYandex(): Promise<void> {
        const params = new URLSearchParams();
        params.append('lang', Settings.LANG_TYPE.toString());
        return http.post('/api/integration/ya/authenticate', params);
    }

    public static authenticateCrazy(): Promise<void> {
        const params = new URLSearchParams();
        params.append('lang', Settings.LANG_TYPE.toString());
        return http.post('/api/integration/crazy/authenticate', params);
    }

    public static authenticateKong(kongId: string, username: string, token: string | null): Promise<void> {
        const params = new URLSearchParams();
        params.append('kongId', kongId);
        params.append('username', username);
        if (token) {
            params.append('token', token);
        }
        return http.post('/api/integration/kong/authenticate', params);
    }

    public static buyYandex(productId: any, signature: any): Promise<number> {
        return http.post('/api/integration/ya/buy?product_id=' + productId, signature);
    }

    public static openTelegramInvoice(productId: any): Promise<string> {
        const params = new URLSearchParams();
        params.append('lang', Settings.LANG_TYPE.toString());
        return http.post('/api/integration/telegram/invoice?product_id=' + productId, params);
    }

    public static buyTelegram(productId: any): Promise<void> {
        const params = new URLSearchParams();
        params.append('lang', Settings.LANG_TYPE.toString());
        return http.post('/api/integration/telegram/buy?product_id=' + productId, params);
    }

    public static register(name: string, password: string, mailOrPhone:string): Promise<void> {
        var params = new URLSearchParams();
        params.append('name', name);
        params.append('password', password);
        if(mailOrPhone.trim() != ""){
            params.append('optionalMailOrPhone', mailOrPhone);
        }
        let ref = IntegrationUtils.getRefCode();
        if(ref){
            params.append('ref', ref);
        }

        return http.post('/api/security/register', params)
            .then((response) => {
                SceneService.reloadScene();
            });
    }

    public static setName(name: string): Promise<void> {
        var params = new URLSearchParams();
        params.append('name', name);

        return http.post('/api/security/name/set', params)
            .then((response) => {
                SceneService.reloadScene();
            });
    }

    public static setMailOrPhone(name: string): Promise<void> {
        var params = new URLSearchParams();
        params.append('mailOrPhone', name);

        return http.post('/api/security/mailOrPhone/set', params);
    }
    public static setPassword(passwordCurrent: string, passwordNew: string): Promise<void> {
        var params = new URLSearchParams();
        params.append('passwordCurrent', passwordCurrent);
        params.append('passwordNew', passwordNew);

        return http.post('/api/security/password/set', params).then((response) => {
            SceneService.reloadScene();
        });
    }

    public static logout(): Promise<void> {
        return http.post('/api/security/logout', new URLSearchParams())
            .then((response) => {
                window.location.reload();
            });
    }

    public static logoutNoReload(): Promise<void> {
        return http.post('/api/security/logout', new URLSearchParams());
    }

    public static login(name: string, password: string): Promise<void> {
        var params = new URLSearchParams();
        params.append('name', name);
        params.append('password', password);
        params.append('lang', Settings.LANG_TYPE.toString());

        return http.post('/api/security/login', params)
            .then(r => r.data)
            .then(() => reload());
    }

    public static isPlayerExist(): Promise<boolean> {
        return http.get('/api/security/isPlayerExist')
            .then(r => r.data)
            .then(rObj => {
                return JSON.parse(rObj);
            });
    }

}
