
import 'reflect-metadata';
import Vue from 'vue';
import Component from 'vue-class-component';
import {NotificationManager} from '@/managers/notification-manager';
import {Http} from "@/constants/http";
import {Settings} from "@/constants/settings";
import {IntegrationUtils} from "@/utils/integration-utils";
import {Version} from "@/constants/version";
import {GameState} from "@/constants/game-state";
import ListenerManager from '@/managers/listener-manager';
import SceneUtils from "@/utils/scene-utils";
import ConnectionStore from "@/store/connection";
import PlayerStore from "@/store/player";
import SecurityStore from "@/store/security";
import CU from '../utils/c-u';
import {InterfaceMode, YaPromocodeResult} from "@/store/player/types";
import Scene = Phaser.Scene;
import {Urls} from "@/constants/urls";
import {Keys} from "@/constants/key";
import SoundUtils from "@/utils/sound-utils";
import {ClanShortInfo} from "@/store/clan/types";
import OnceCaller from "@/utils/once-caller";
import {DomainUtils} from "@/utils/domain-utils";
import http from "@/axios";
import PromoUtils from "@/utils/promo-utils";

const RegisterPage = () => ({
  component: import('@/pages/RegisterPage.vue'),
})

const RatingPage = () => ({
  component: import('@/pages/RatingPage.vue'),
})
const ClanRatingPage = () => ({
  component: import('@/pages/ClanRatingPage.vue'),
})
const LoginPage = () => ({
  component: import('@/pages/LoginPage.vue'),
})
const ChatPage = () => ({
  component: import('@/pages/ChatPage.vue'),
})
const MailPage = () => ({
  component: import('@/pages/MailPage.vue'),
})
const ConnectionPage = () => ({
  component: import('@/pages/ConnectionPage.vue'),
})
const RenamePage = () => ({
  component: import('@/pages/RenamePage.vue'),
})
const MailOrPhonePage = () => ({
  component: import('@/pages/MailOrPhonePage.vue'),
})
const PasswordPage = () => ({
  component: import('@/pages/PasswordPage.vue'),
})
const ClanNamePage = () => ({
  component: import('@/pages/ClanNamePage.vue'),
})
const ClanAboutPage = () => ({
  component: import('@/pages/ClanAboutPage.vue'),
})
const EditPage = () => ({
  component: import('@/pages/EditPage.vue'),
})


@Component({
  components: {
    PasswordPage,
    ClanNamePage,
    RenamePage,
    MailOrPhonePage,
    ConnectionPage,
    RegisterPage,
    LoginPage,
    RatingPage,
    ClanRatingPage,
    ChatPage,
    MailPage,
    ClanAboutPage,
    EditPage
  }
})
export default class MainView extends Vue {
  private downloaded: boolean = false;
  private gameInstance: any = null;
  private containerId: string = 'game-container';

  private footerId: string = 'footer-container';
  private headerId: string = 'header-container';

  private chatRoom: string = Settings.MAIN_CHAT_ROOM;
  private header: string | undefined = '';
  private footer: string | undefined = '';

  private registerPageVisible: boolean = false;
  private ratingPageVisible: boolean = false;
  private clanRatingPageVisible: boolean = false;
  private loginPageVisible: boolean = false;
  private chatPageVisible: boolean = false;
  private mailPageVisible: boolean = false;
  private connectionPageVisible: boolean = false;
  private renamePageVisible: boolean = false;
  private mailOrPhonePageVisible: boolean = false;
  private passwordPageVisible: boolean = false;
  private clanNamePageVisible: boolean = false;
  private clanAboutPageVisible: boolean = false;
  private editPageVisible: boolean = false;

  private static loggedErrors: number = 0;

  private logClError(txt: string) {
    if (MainView.loggedErrors < 3) {
      PlayerStore.logClientError(txt);
      ++MainView.loggedErrors;
    }
  }

  protected created() {
    Settings.setLangDefaults();
    window.addEventListener('beforeunload', this.leaving);
  }

  protected async mounted() {
    let self = this;

    if (IntegrationUtils.isYandexUser()) {
      // @ts-ignore
      YaGames.init({ signed: true }).then(async (ysdk: any) => {
        // @ts-ignore
        window.ysdk = ysdk;
        // @ts-ignore
        window.ysdk.getPlayer().then(async (_player: any) => {
          Http.YA_UNIQUE_ID = _player.getUniqueID();
          SecurityStore.authenticateYandex().then(() => {
            self.init();
          });
        });
        ysdk.features.LoadingAPI?.ready();
      });
    } else if (IntegrationUtils.isTelegramUser()) {
      // @ts-ignore
      window.Telegram.WebApp.ready();
      // @ts-ignore
      Http.TELEGRAM_ID = window.Telegram.WebApp.initDataUnsafe.user!.id;

      SecurityStore.authenticateTelegram().then(() => self.init());
    } else if (IntegrationUtils.isCrazyUser()) {

      // @ts-ignore
      await window.CrazyGames.SDK.init();

      // @ts-ignore
      let user = await window.CrazyGames.SDK.user.getUser();
      console.log("Get user result", user);

      if (!user) {
        GameState.REQUIRE_CRAZY_LOGIN = true;
        // @ts-ignore
        window.CrazyGames.SDK.game.loadingStart();
        SecurityStore.logoutNoReload().then(() => {
          self.init();
        });
      } else {
        try {


          // @ts-ignore
          window.CrazyGames.SDK.user.getUserToken().then((token: any) => {
            console.log("Get token result", token);

            Http.CRAZY_ID = token;
            // @ts-ignore
            window.CrazyGames.SDK.game.loadingStart();
            SecurityStore.authenticateCrazy().then(() => {
              self.init();
            });
          });

          // // @ts-ignore
          // let token = await window.CrazyGames.SDK.user.getUserToken();
          // console.log("Get token result", token);
          //
          // Http.CRAZY_ID = token;
          // // @ts-ignore
          // window.CrazyGames.SDK.game.loadingStart();
          // SecurityStore.authenticateCrazy().then(() => {
          //   self.init();
          // });

        } catch (e) {
          console.log("getUserToken Error:", e);
        }
      }

    } else if (IntegrationUtils.isKongUser()) {

      // @ts-ignore
      kongregateAPI.loadAPI(function(){
        // @ts-ignore
        window.kongregate = kongregateAPI.getAPI();

        // @ts-ignore
        let kongId = window.kongregate.services.getUserId();
        // @ts-ignore
        let userName = window.kongregate.services.getUsername();

        // @ts-ignore
        let token = window.kongregate.services.getGameAuthToken();

        Http.KONG_ID = kongId;

        // @ts-ignore
        if (window.kongregate.services.isGuest()) {
          GameState.REQUIRE_KONG_LOGIN = true;
          Http.KONG_ID = null;

          SecurityStore.authenticateKong(kongId, userName, null).then(() => {
            self.init();
          });
        } else {
          SecurityStore.authenticateKong(kongId, userName, token).then(() => {
            self.init();
          });
        }
      });

    } else if (IntegrationUtils.isMystoreUser()) {
      IntegrationUtils.initMystore();
      this.init();
    } else if (IntegrationUtils.isExeUser()) {
      IntegrationUtils.initExeAPI();
      this.init();
    } else {
      this.init();
    }
  }

  protected leaving() {
    PlayerStore.logUnload(GameState.LOADED_PCT);
  }

  private async init() {
    let self = this;

    (function(){

      var originallog = console.error;

      console.error = function(txt) {
        self.logClError(txt);
        originallog.apply(console, [txt])
      }

    })();

    window.addEventListener("error", function (e) {
      let message = (String(Version.VERSION_NUM) + ' --- ');
      let scene: Scene | undefined = SceneUtils.getCurrentScene();
      if (scene && scene.scene && scene.scene.key) {
        message += (scene.scene.key + ' : ');
      }
      if (e) {
        if (e.message) {
          message += (e.message + '  ====  ');
        }
        if (e.error && e.error.message) {
          message += (e.error.message);
        }
        if (e.filename) {
          message += ':::';
          message += String(e.filename);
        }
        if (e.lineno) {
          message += ':::';
          message += String(e.lineno);
        }
        if (e.colno) {
          message += ':';
          message += String(e.colno);
        }
      }
      self.logClError(message);
      return false;
    });

    let timerId = setInterval(() => {
      if (Settings.LOGGED_GAME_STARTED) {
        clearInterval(timerId);
      } else {
        if (document && document.hidden) {
          PlayerStore.logHidden();
        } else {
          PlayerStore.logActive();
        }
      }
    }, 5000);

    document.addEventListener("visibilitychange", function(){
      if (document.hidden) {
        SoundUtils.soundOff();
      } else {
        SoundUtils.soundOn();
      }
    });

    document.addEventListener("onPause", () => {
      SoundUtils.soundOff();
    }, false);

    document.addEventListener("onResume", () => {
      SoundUtils.soundOn();
    }, false);

    const load = async () => {
      while (true) {
        try {
          return await import(/* webpackChunkName: "game" */ '@/game/game')
        } catch (e) {
          console.log('Import error. Retrying');
          console.log(e);
        }
      }
    };

    const fetchPlayer = async () => {
      let attempts = 0;
      while (true) {
        try {
          await PlayerStore.fetchPlayerInfoCurrent(true);
          let player = PlayerStore.getPlayer();
          if (player && player.interfaceMode === InterfaceMode.ORIGINAL) {
            for (let road of Keys.ROADS_UI) {
              Urls.IMAGES.set(road, `assets/bg/ui1/${road}.jpg`);
            }
            Settings.KNIGHT_OFFSET_X = 0;
          }
          return;
        } catch (e) {
          ++attempts;
          if (attempts > 3) {
            this.connectionPageVisible = true;
            break;
          } else {
            console.log('Player fetch error. Retrying');
            console.log(e);
          }
        }
      }
    };

    ListenerManager.setSessionCreateCallback(() => {
      NotificationManager.initNotifications();
    });

    ListenerManager.setConnectionLostCallback(() => {
      console.log('Lost connection');
      ConnectionStore.checkServerConnection().catch(() => {
        this.connectionPageVisible = true;
        self.logClError('Server connection error');
      });
    });

    let [game, pl] = await Promise.all([load(), fetchPlayer()]);

    this.downloaded = true;

    this.header = PlayerStore.getHeader();
    this.footer = PlayerStore.getFooter();

    this.$nextTick(() => {

      this.gameInstance = game.launch(this.containerId, this.headerId, this.footerId);

      const canvas = <HTMLElement> document.getElementsByTagName('canvas').item(0);
      canvas.style.width = String(CU.CSS_WIDTH) + 'px';
      canvas.style.height = String(CU.CSS_HEIGHT) + 'px';

      ListenerManager.setRegisterCallback(() => {
        this.registerPageVisible = true;
      });

      ListenerManager.setLoginCallback(() => {
        this.loginPageVisible = true;
      });

      ListenerManager.setRatingCallback(() => {
        this.ratingPageVisible = true;
      });

      ListenerManager.setClanRatingCallback(() => {
        this.clanRatingPageVisible = true;
      });

      ListenerManager.setChatOpenCallback((chatRoom: string) => {
        this.chatRoom = chatRoom;
        this.chatPageVisible = true;
      });

      ListenerManager.setMailCallback(() => {
        this.mailPageVisible = true;
      });

      ListenerManager.setRenamePageOpenCallback(() => {
        this.renamePageVisible = true;
      });

      ListenerManager.setChangeEmailOrPhonePageOpenCallback(() => {
        this.mailOrPhonePageVisible = true;
      });

      ListenerManager.setPasswordPageOpenCallback(() => {
        this.passwordPageVisible = true;
      });

      ListenerManager.setClanNamePageOpenCallback(() => {
        this.clanNamePageVisible = true;
      });

      ListenerManager.setClanAboutPageOpenCallback(() => {
        this.clanAboutPageVisible = true;
      });

      ListenerManager.setEditPageOpenCallback(() => {
        this.editPageVisible = true;
      });

      ListenerManager.setEditPageCloseCallback(() => {
        this.editPageVisible = false;
        CU.unblockCanvas();
      });

      this.$root.$on("openProfileCallback", (playerId: number) => {
        ListenerManager.callProfileCallback(playerId);
        this.closeRatingPage();
        this.closeClanRatingPage();
        this.closeChatPage();
        this.closeMailPage();
      })

      this.$root.$on("showClanDetailsPanel", (ci: ClanShortInfo) => {
        ListenerManager.callClanDetailsCallback(ci);
        this.closeRatingPage();
        this.closeClanRatingPage();  //TODO надо не закрывать панель, а отображать поверх (см. openClanDetailsPanel)
        this.closeChatPage();
        this.closeMailPage();
      })

      window.addEventListener("resize", () => {
        if (!CU.isCanvasBlocked() || IntegrationUtils.isCrazyUser()) {
          CU.WINDOW_RESIZED = true;

          let scene = SceneUtils.getCurrentScene();
          //@ts-ignore
          if (IntegrationUtils.isCrazyUser() && typeof scene.reload === 'function') {
            if (scene.scene.isActive()) {
              OnceCaller.doOnce("checkCanvasResizeCS", () => {
                if (SceneUtils.getCurrentScene().scene.isActive()) {
                  SceneUtils.getCurrentScene().time.delayedCall(500, () => {
                    CU.checkCanvasSize(SceneUtils.getCurrentScene(), () => {
                      CU.WINDOW_RESIZED = false;
                      //@ts-ignore
                      scene.reload();
                    });
                  });
                }
              }, 250);
            }
          }
        }
      },false);

      if (IntegrationUtils.isYandexUser()) {
        try {
          // @ts-ignore
          window.ysdk.on('BONUS_AWARDED', (obj: any) => {
            try {
              if (!!obj && obj.hasOwnProperty('signature')) {
                console.log('obj.signature =' + obj.signature);
                PromoUtils.handleYaPromocode(obj.signature);
              } else {

                // @ts-ignore
                window.ysdk.bonuses.getPlayerBonuses().then((obj: any) => {
                  try {
                    if (!!obj && obj.hasOwnProperty('signature')) {
                      console.log('obj.signature =' + obj.signature);
                      PromoUtils.handleYaPromocode(obj.signature);
                    } else {
                      console.log('No obj signature recieved');
                      console.log(obj);
                      console.log('==========');
                    }
                  } catch (ignore) {}
                });

              }
            } catch (ignore) {}
          });
        } catch (ignore) {}
      }
    });

    IntegrationUtils.importPaymentSDK(PlayerStore.getPlayer());

    ListenerManager.setCloseChatCallback(() => {
      this.closeChatPage();
      this.closeMailPage();
    });

    let player = PlayerStore.getPlayer();
    if (!!player && DomainUtils.canChangeDomain(player)) {
      PlayerStore.fetchPlayerId(DomainUtils.getDirectDomain()).then(id => {
        if (player.id === id) {
          console.log('IDSs are the same. Switching to direct domain');
          http.defaults.baseURL = DomainUtils.getDirectDomain();
          console.log('set axios.defaults.baseURL to ' + http.defaults.baseURL);
        } else {
          console.log('Not getting same player ID from direct domain. Not Switching');
        }
      }).catch(function (error) {
        console.log('Cannot get player ID from direct domain. Not Switching');
        return 0;
      });
    }
  }

  protected destroyed() {
    this.gameInstance.destroy(false);
  }

  protected closeRegisterPage() {
    this.registerPageVisible = false;
    CU.unblockCanvas();
  }

  protected closeLoginPage() {
    this.loginPageVisible = false;
    CU.unblockCanvas();
  }

  protected closeRatingPage() {
    this.ratingPageVisible = false;
    CU.unblockCanvas();
  }

  protected closeClanRatingPage() {
    this.clanRatingPageVisible = false;
    CU.unblockCanvas();
  }

  protected async closeChatPage() {
    this.chatPageVisible = false;
    await ListenerManager.callChatSeenCallback(false);
    CU.unblockCanvas();
  }

  protected async closeMailPage() {
    this.mailPageVisible = false;
    await PlayerStore.fetchPlayerInfoCurrent();
    CU.unblockCanvas();
  }

  protected closeRenamePage() {
    this.renamePageVisible = false;
    CU.unblockCanvas();
  }

  protected closeEmailOrPhonePage() {
    this.mailOrPhonePageVisible = false;
    CU.unblockCanvas();
  }

  protected closePasswordPage() {
    this.passwordPageVisible = false;
    CU.unblockCanvas();
  }

  protected closeClanNamePage() {
    this.clanNamePageVisible = false;
    CU.unblockCanvas();
  }

  protected closeClanAboutPage() {
    this.clanAboutPageVisible = false;
    ListenerManager.callClanAboutPageCloseCallback()
    CU.unblockCanvas();
  }

  protected closeEditPage() {
    this.editPageVisible = false;
    ListenerManager.callEditPageCloseCallback();
    CU.unblockCanvas();
  }
}
