import axios from 'axios';
import {Http} from "@/constants/http";
import PlayerStore from "@/store/player";
import {reload} from '@/utils/document-utils';

/** setting base header  */
const instance = process.env.VUE_APP_IS_MOBILE_APP === 'true' ? axios.create({baseURL: process.env.VUE_APP_API_HOST}) : axios.create();
instance.interceptors.request.use(config => {
  config.headers.get['Content-Type'] = 'application/json';
  config.headers.post['Content-Type'] = 'application/json';
  config.headers.put['Content-Type'] = 'application/json';
  config.headers.patch['Content-Type'] = 'application/json';
  config.headers.delete['Content-Type'] = 'application/json';
  config.withCredentials = true;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const okLoggedUserId = urlParams.get('logged_user_id');
  const vkViewerId = urlParams.get('viewer_id');
  const mystoreUid = urlParams.get('uid');
  const fotoUid = urlParams.get('fotoUid');
  const exeUid = urlParams.get('exe_user');
  const beelineUid = urlParams.get('beeline_login');

  let token = urlParams.get('Authorization')
  let jsession = urlParams.get('jsession');
  if (!!Http.JSESSION) {
    jsession = Http.JSESSION;
  }
  if (!!Http.AUTHORIZATION) {
    token = Http.AUTHORIZATION;
  }
  if (!!jsession) {
    config.params = config.params || {};
    config.params['JSESSIONID'] = jsession;
  }
  if (!!token) {
    config.headers.Authorization = !!token ? token : '';
  }

  config.headers['logged_user_id'] = !!okLoggedUserId ? okLoggedUserId : '';
  config.headers['viewer_id'] = !!vkViewerId ? vkViewerId : '';
  config.headers['exe_user'] = !!exeUid ? exeUid : '';
  config.headers['uid'] = !!mystoreUid ? mystoreUid : '';
  config.headers['fotoUid'] = !!fotoUid ? fotoUid : '';
  config.headers['beeline_login'] = !!beelineUid ? beelineUid : '';

  if (!!Http.YA_UNIQUE_ID) {
    config.headers['ya_unique_id'] = Http.YA_UNIQUE_ID;
  }

  if (!!Http.TELEGRAM_ID) {
    config.headers['tg_user_id'] = Http.TELEGRAM_ID;
  }

  if (!!Http.CRAZY_ID) {
    config.headers['crazyId'] = Http.CRAZY_ID;
  }

  if (!!Http.KONG_ID) {
    config.headers['kongId'] = Http.KONG_ID;
  }

  if (!!Http.MYSTORE_UID) {
    config.headers['uid'] = Http.MYSTORE_UID;
  }

  return config;
});

instance.interceptors.response.use((response) => {
  const jsession = response.headers['jsession'];
  const authorization = response.headers['authorization'];
  if (!!jsession) {
    Http.JSESSION = jsession;
  }
  if (!!authorization) {
    Http.AUTHORIZATION = authorization;
  }
  if (response.status === 204) {
    reload();
  }
  return response;
}, (error) => {
  if (error.response && error.response.status === 307 && !!error.response.data && !!error.response.data.message) {
    window.location.replace(error.response.data.message);
  }
  if (PlayerStore.getPlayer() && !!error.response && error.response.status === 401) {
    if (!error.request.responseURL.includes('api/player/id/current')) {
      reload();
    } else {
      console.log('Not reloading');
    }
  }
  return Promise.reject(error);
});

export default instance;
